<template>
    <div>
        <Navigation />
        <!-- 议论文 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <!-- <img src="@/assets/wordOp/EsayTitle.png" alt="" class="title"> -->
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI议论文" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">文章主题：</p>
                            <div class="upload_box">
                                <el-input v-model="form.business" placeholder="请录入文章主题"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">修辞手法：</p>
                            <div class="upload_box">
                                <el-input v-model="form.figure" placeholder="如:比喻、夸张、排比、对偶"></el-input>
                            </div>
                        </div>
                        <ai_select selectTitle="文章结构" :select_list="selectList" @getSelectVal="get_type" />
                        <ai_select selectTitle="文章字数" :select_list="numList" @getSelectVal="get_num" />
                        <ai_select selectTitle="论证方式" :select_list="argumentList" @getSelectVal="get_argument" />
                        <ai_select selectTitle="论文类型" :select_list="contentList" @getSelectVal="get_content" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">细节描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.desc" placeholder="请用一段话，描述您要生成文章的细节"></el-input>
                            </div>

                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />

    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_select from '@/components/ai_select.vue'
import ai_text_content from '@/components/ai_text_content.vue'
import ai_button_file_data from '@/components/ai_button_file_data.vue'
export default {
    name: '',
    mounted() { this.getUserInfo() },
    components: {
        wordNav,
        ai_title,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                // 议论文
                type: '', // 文章结构
                business: '', // 文章主题
                figure: '',// 修辞手法
                content: '', // 内容
                desc: '', //观点描述
                num: '', //字数
                argument: '', //论证方式
            },
            contentData: {}, //内容
            selectList: [
                {
                    id: '不限结构',
                    name: '不限结构'
                },
                {
                    id: '总分结构',
                    name: '总分结构'
                },
                {
                    id: '递进结构',
                    name: '递进结构'
                },
                {
                    id: '并列结构',
                    name: '并列结构'
                },
                {
                    id: '对照结构',
                    name: '对照结构'
                },
            ],
            argumentList: [{
                id: '立论',
                name: '立论'
            }, {
                id: '驳论',
                name: '驳论'
            }],
            contentList: [
                {
                    id: '一般性议论文',
                    name: '一般性议论文',
                },
                {
                    id: '评论',
                    name: '评论',
                },
                {
                    id: '随笔',
                    name: '随笔',
                },
                {
                    id: '杂谈',
                    name: '杂谈',
                },
                {
                    id: '笔记',
                    name: '笔记',
                },
                {
                    id: '读后感',
                    name: '读后感',
                },
            ],
            numList: [
                {
                    id: 500,
                    name: 500,
                },
                {
                    id: 1000,
                    name: 1000,
                },
                {
                    id: 1500,
                    name: 1500,
                },
            ],//字数限制
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        get_type(val) {
            this.form.type = val
        },
        get_num(val) {
            this.form.num = val
        },
        get_argument(val) {
            this.form.argument = val
        },
        get_content(val) {
            this.form.content = val
        },
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning'
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入主题描述',
            //         type: 'warning'
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning'
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true

                // this.postData()
            }
        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            let data = {}
            data.mobile = this.$user_info.mobile
            for (const key in this.form) {
                data[key] = this.form[key]
            }
            this.curlPost('/file/comment_create', data).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()
                        }
                    })
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>